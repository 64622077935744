<template>
  <v-container>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans">Solicitações de saque</span>
      </h1>

      <v-spacer />

      <v-chip class="mr-2" color="surface" link @click="handleOrder()">
        <span>
          {{ pagination.order === 0 ? "Mais Recentes" : "Mais Antigo" }}
        </span>

        <v-icon right>
          {{ pagination.order === 0 ? "mdi-chevron-down" : "mdi-chevron-up" }}
        </v-icon>
      </v-chip>

      <v-chip color="surface" link>
        <span> Exportar </span>
        <v-icon right>mdi-download</v-icon>
      </v-chip>
    </div>

    <loader-content v-if="loading" />

    <section v-else>
      <FilterGroup class="mb-4" :filters="types" :queryKey="'type'" />

      <FilterInput
        class="mb-4"
        :dataFilters="require('@/assets/filters/withdrawals.json')"
      />

      <v-card class="shadow rounded-lg mb-6" color="backgorund">
        <v-data-table
          v-model="selectedItems"
          class="transparent"
          item-key="saqueId"
          :headers="_headers"
          :items="withdrawals"
          hide-default-footer
          disable-pagination
          disable-sort
          show-select
        >
          <!-- user -->
          <template v-slot:[`item.nome`]="{ item }">
            <router-link :to="`/user/${item.userID || item.id}`">
              <v-chip color="primary">
                <v-icon small left>mdi-account</v-icon>

                <span class="cut">{{ item.nome }}</span>
              </v-chip>
            </router-link>
          </template>

          <!-- value -->
          <template v-slot:[`item.valor`]="{ item }">
            R$ {{ currencyMask(item.valor) }}
          </template>

          <!-- final Value -->
          <template v-slot:[`item.finalValue`]="{ item }">
            <div v-if="item.DadosSaque.taxas" class="d-flex flex-column">
              <span>
                R$
                {{
                  currencyMask(
                    Number(item.valor) - Number(item.DadosSaque.taxas.taxaSaque)
                  )
                }}
              </span>

              <span class="subtext--text text-caption">
                Taxa: {{ currencyMask(item.DadosSaque.taxas.taxaSaque) }}
              </span>
            </div>

            <div v-else>R$ {{ currencyMask(item.valor) }}</div>
          </template>

          <template v-slot:[`item.dados`]="{ item }">
            <div v-if="item.tipo === '0'" class="d-flex flex-column">
              <span
                >{{ item.DadosSaque.agencia }} /
                {{ item.DadosSaque.conta }}</span
              >
              <span class="subtext--text text-caption cut">
                {{ item.DadosSaque.banco }}
              </span>
            </div>

            <div v-else class="d-flex flex-column">
              <span>{{ item.DadosSaque.chave }}</span>
              <span class="subtext--text text-caption">
                {{ item.DadosSaque.tipoChave }}
              </span>
            </div>
          </template>

          <template v-slot:[`item.nomeContrato`]="{ item }">
            {{ item.nomeContrato }}
          </template>

          <!-- status -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="handleStatus(item.status).color" label small dark>
              {{ handleStatus(item.status).label }}
            </v-chip>
          </template>

          <!-- actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="rounded-lg" icon @click="handleManage(item)">
              <v-icon>mdi-open-in-app</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        @input="getData()"
      />
    </section>

    <ManageWithdraw ref="manageWithdraw" @success="$emit('refresh')" />
  </v-container>
</template>

<script>
import { request } from "@/services";
import { currencyMask, handlePagination } from "@/utils";
import FilterGroup from "@/components/filters/FilterGroup";
import FilterInput from "@/components/filters/FilterInput";
import ManageWithdraw from "@/components/withdrawals/ManageWithdraw.vue";
const moment = require("moment");

export default {
  name: "Customers",

  data() {
    return {
      loading: true,
      types: [
        { label: "Todos", value: "" },
        { label: "Saque", value: "0" },
        { label: "Liquidação", value: "1" },
      ],
      selectedItems: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        order: 0,
      },
      filters: null,
      withdrawals: [],
    };
  },

  components: {
    FilterGroup,
    FilterInput,
    ManageWithdraw,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    _headers() {
      return [
        { text: "ID", value: "saqueId" },
        { text: "Cliente", value: "nome" },
        { text: "Valor Solicitado", value: "valor" },
        { text: "Valor Final", value: "finalValue" },
        { text: "Dados", value: "dados" },
        { text: "Produto", value: "nomeContrato" },
        { text: "Data", value: "data" },
        { text: "Status", value: "status", align: "center" },
        { text: "Ações", value: "actions", align: "center" },
      ];
    },

    _fileName() {
      return `diversi_withdrawals_export_${moment().format("YYYY-MM-DD")}.csv`;
    },
  },

  methods: {
    async getData() {
      try {
        const payload = {
          method: "listSaquesAdmin",
          page: this.pagination.page - 1,
          order: this.pagination.order,
          ...this.filters,
        };

        await request(payload).then(async (res) => {
          this.withdrawals = res.saques;
          this.pagination.pageCount = handlePagination(res.total);
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleManage(data) {
      this.$refs.manageWithdraw.open(data);
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 1;

      const query = {
        FiltroUserID: value.id || undefined,
        nameFilter: value.name || undefined,
        emailFilter: value.email || undefined,
        statusFilter: value.status || undefined,
        dataInicial: value.period ? value.period.split(",")[0] : undefined,
        dataFinal: value.period ? value.period.split(",")[1] : undefined,
        liquidaFilter: value.type || undefined,
      };

      this.filters = query;

      this.getData();
    },

    handleOrder() {
      this.pagination.order = this.pagination.order === 1 ? 0 : 1;
      this.getData();
    },

    handleStatus(value) {
      switch (String(value)) {
        case "0":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "1":
          return {
            label: "Aprovado",
            color: "green",
          };
        case "-1":
          return {
            label: "Recusado",
            color: "red",
          };
        default: {
          return {
            label: "Carregando",
            color: "grey",
          };
        }
      }
    },

    currencyMask,
  },
};
</script>

<style></style>
